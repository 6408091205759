import React, { useState } from "react";
import { Card as AntCard } from "antd";
import { inject, observer } from "mobx-react";

import Icon from "../icon";
import Image from "../image";
import { photoSizes } from "../../constants";
import { MaterialCardModal } from "../modals";
import { proxy } from "../../common/apiUrls";

import "./styles.scss";
import { DateService } from "../../services";
import { dateFormat } from "../catalogList/catalogListItem/settings";
import { useTranslation } from "react-i18next";

const MaterialCard = ({ zoneName, subZoneName, material, catalogStore: { activeCatalog, materialDataLoadingSet } }) => {
  const { t } = useTranslation();
  const [modalVisibility, setModalVisibility] = useState(false);
  const { id, merch_name, code, photo, message_code, item_type, cling_type_code, mmrc_end_date } = material;

  const url = photo ? photo[photoSizes.medium] : null;

  function getTitle() {
    return (
      <span id={`material-card-title-${id}`} className="card__name">
        {merch_name || cling_type_code}
      </span>
    );
  }

  function getDescription() {
    return (
      <>
        <span id={`material-card-description-${id}`} className="block card__code semibold">
          {code}
        </span>
        {message_code && <span className="block">{message_code}</span>}
        <span className="bold card__description">{activeCatalog.anotherFCIncluded ? "" : `${zoneName} : ${subZoneName}`}</span>
        {item_type === "MarketingCling" && (
          <span className="block semibold card__date">{t("materialCard.validUntil", { date: DateService.getFormattedDate(mmrc_end_date, dateFormat) })}</span>
        )}
      </>
    );
  }

  function cartCover() {
    const alt = photo && photo.description ? photo.description : `${merch_name}-${code}`;
    return (
      <>
        <div className="card__options">
          <button
            tabIndex={0}
            type="button"
            aria-labelledby={`material-card-title-${id}`}
            aria-describedby={`material-card-description-${id}`}
            className="card__options__btn"
            style={{ width: "100%" }}
          >
            <Icon type="options" /> {t("materialCard.selectOptions")}
          </button>
        </div>
        <Image alt={alt} url={url} />
      </>
    );
  }

  function toggleModalVisibility() {
    materialDataLoadingSet(!modalVisibility);
    setModalVisibility(!modalVisibility);
  }

  return (
    <>
      <AntCard
        className={`material-card ${material.item_type === "MarketingCling" ? "cling-card" : ""}`}
        hoverable
        bordered={false}
        style={{ borderRadius: 4 }}
        cover={cartCover()}
        onClick={toggleModalVisibility}
      >
        <AntCard.Meta title={getTitle()} description={getDescription()} />
      </AntCard>
      {modalVisibility && <MaterialCardModal material={material} visible={modalVisibility} onCloseModal={toggleModalVisibility} />}
    </>
  );
};

export default inject(({ rootStore }) => ({
  catalogStore: rootStore.catalogStore
}))(observer(MaterialCard));
